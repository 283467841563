import moment from "moment";

import {DateRangeTypes} from "~constants/dates/dateRange";

export const getDateRangeForDateRangeType = ({
  dateRangeType,
}: {
  dateRangeType: DateRangeTypes;
}): {
  startDate: moment.Moment;
  endDate: moment.Moment;
} => {
  const today = moment();
  const yesterday = today.clone().subtract(1, "days");

  switch (dateRangeType) {
    case DateRangeTypes.THIRTY_DAYS: {
      return {
        startDate: today.clone().add(-30, "d"),
        endDate: yesterday,
      };
    }
    case DateRangeTypes.THIS_MONTH: {
      return {
        startDate: today.clone().startOf("month"),
        endDate: today,
      };
    }
    case DateRangeTypes.THIS_WEEK: {
      return {
        startDate: today.clone().startOf("week"),
        endDate: today,
      };
    }
    case DateRangeTypes.TODAY: {
      return {
        startDate: today.clone().startOf("day"),
        endDate: today.clone().endOf("day"),
      };
    }
    case DateRangeTypes.YESTERDAY: {
      return {
        startDate: today.clone().subtract(1, "days").startOf("day"),
        endDate: today.clone().subtract(1, "days").endOf("day"),
      };
    }
    case DateRangeTypes.SEVEN_DAYS: {
      return {
        startDate: today.clone().add(-7, "d"),
        endDate: yesterday,
      };
    }
    default: {
      return {
        startDate: today.clone().add(-30, "d"),
        endDate: today,
      };
    }
  }
};

export const extractDate = (isoString: string) => {
  return moment(isoString).format("YYYY-MM-DD");
};

export const extractTime = (isoString: string) => {
  return moment(isoString).format("HH:mm:ss");
};

export const combineDateTimeToISO = (date: string, time: string) => {
  return moment(`${date}T${time}`).toISOString();
};
